<template>
  <list-template
    ref="tableList"
    :search-config="searchConfig"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onHandle="handleClick"
    @onSearch="onSearch"
    @onChange="onChange">
  </list-template>
</template>

<script>
import { getApi2 } from '@/api'
import { tableListMixin } from '@/utils/mixins'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  mixins: [tableListMixin],
  methods: {
    onBeforeSearch(val) {
      if(JSON.stringify(val) === '{}') {
        this.searchConfig[1].options = []
      }
      this.search = val
    },
    getData() {
      this.getDataApi('/api2/api/feedback/problem/lists')
    },
    getSearchConfig() {
      getApi2('/feedback/problem/type').then(res => {
        this.feedbackConfig = res
      })
      getApi2('/feedback/problem/school').then(res => {
        this.searchConfig[2].options = res
      })
    },
    handleClick(row) {
      this.$router.push(`./detail?id=${row.id}`)
    },
  },
  created() {
    this.getSearchConfig()
  },
  data() {
    return {
      feedbackConfig: {},
      searchConfig: [
        {
          tag: 'select',
          placeholder: '选择用户类型',
          prop: 'role',
          options: [
            { label: '学生', value: 1 },
            { label: '教职工', value: 2 }
          ],
          change: (val, search) => {
            this.searchConfig[1].options = val === 1 ? this.feedbackConfig.student : this.feedbackConfig.teacher
            const { type, ...other } = search
            this.$refs.tableList.$children[0].search = {...other}
            this.search = { ...other }
          }
        },
        {
          tag: 'select',
          placeholder: '选择问题分类',
          prop: 'type',
          label: 'name',
          value: 'id',
          options: [],
        },
        {
          tag: 'select',
          placeholder: '选择择校区',
          prop: 'school_id',
          label: 'school_name',
          value: 'school_id',
          options: [],
        },
        {
          tag: 'input',
          prop: 'content',
          placeholder: '请输入反馈内容'
        }
      ],
      tableConfig: [
        {
          width: '80rem',
          label: '序号',
          type: 'index',
          textAlign: 'left'
        },
        {
          prop: 'type',
          label: '问题分类'
        },
        {
          prop: 'content',
          label: '反馈内容'
        },
        {
          prop: 'name',
          label: '反馈人'
        },
        {
          prop: 'role',
          label: '用户类型'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'created_at',
          label: '提交时间'
        },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          buttons: [{ type:"view", text:"详情" }]
        }
      ]
    }
  }
}
</script>
